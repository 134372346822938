<template>
  <section class="not__found__page">
    <div class="not__found__page__content box">
      <div class="background__404">
        <img src="@/assets/icons/404.svg" alt="404 image">
      </div>
      <h3>Oops, it seems something exploded</h3>
      <router-link :to="{ path: '/'}" >
        <button>Go to homepage</button>
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: 'not-found'
}
</script>

<style lang="scss" scoped>
.not__found__page {
  max-width: 1200px;
  margin: 42px auto;  
  h1, h3 {
    margin: 0px;
  }
  .box {
    padding: 16px;  
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;  
    gap: 16px;
    text-transform: capitalize;
    h1 {
      font-size: 72px;
    }
    h3 {
      font-style: italic;
    }
    a {
      cursor: default;
    }
    .background__404{
      width: 100%;
      max-width: 464px;
      max-height: 362px;
      img{
          width: 100%;
          height: 100%;
          object-fit: contain;
      }
    }
    button{
        cursor: pointer;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 700;
    }
  }    
}
</style>
